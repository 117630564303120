<template>
  <XeGenericErrorModal
    :value="showModal"
    :title="$t('PageAccountLocked.TitleModal').value"
    :figure-title="$t('PageAccountLocked.Title').value"
    :figure-content="$t('PageAccountLocked.Description').value"
    :action-text="$t('PageAccountLocked.ButtonResetPassword').value"
    @input="$router.push({ name: 'Login' })"
    @actionClick="$router.push({ name: 'ForgotPassword' })"
  />
</template>

<script>
import { ref } from '@vue/composition-api'
import { useI18nStore, useAnalyticsStore } from '@galileo/stores'
import XeGenericErrorModal from '@galileo/components/XeGenericErrorModal/XeGenericErrorModal'
import {
  SEGMENT_ERRORS,
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
} from '@galileo/constants/segmentAnalytics'

export default {
  name: 'AccountLocked',
  components: {
    XeGenericErrorModal,
  },
  setup() {
    const { $t } = useI18nStore()
    const showModal = ref(true)
    const analyticsStore = useAnalyticsStore()

    analyticsStore.track({
      event: SEGMENT_EVENTS.LOGIN_FAILED,
      traits: {
        errorType: SEGMENT_ERRORS.LOGIN_ATTEMPTS,
      },
    })

    return {
      showModal,
      $t,
    }
  },
}
</script>

<style scoped></style>
