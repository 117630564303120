<template>
  <AppModal v-model="model" :title="title">
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #right>
          <AppBackButton
            v-if="!hideCloseButton"
            analytics-name="error-modal-back"
            @click="dismiss"
          />
        </template>
      </AppModalHeader>
    </template>
    <slot>
      <AppCardImageTitle :alt="figureTitle" :src="figureImage" :title="figureTitle">
        {{ figureContent }}
      </AppCardImageTitle>
    </slot>
    <template #footer>
      <AppModalFooter>
        <AppButton analytics-name="error-modal-action" @click="actionClick">
          {{ actionText }}
        </AppButton>
      </AppModalFooter>
    </template>
  </AppModal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import {
  AppBackButton,
  AppButton,
  AppCardImageTitle,
  AppModal,
  AppModalHeader,
  AppModalFooter,
  useVModel,
} from '@oen.web.vue2/ui'

export default {
  name: 'XeGenericErrorModal',
  components: {
    AppModal,
    AppModalHeader,
    AppButton,
    AppCardImageTitle,
    AppModalFooter,
    AppBackButton,
  },
  emits: ['input', 'actionClick'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: useI18nStore().$t('ComponentXeGenericErrorModal.Title').value,
    },
    figureTitle: {
      type: String,
      required: false,
      default: useI18nStore().$t('ComponentXeGenericErrorModal.FigureTitle').value,
    },
    figureImage: {
      type: String,
      required: false,
      default: require('@galileo/assets/images/illustrations/warning.svg'),
    },
    figureContent: {
      type: String,
      required: false,
      default: useI18nStore().$t('ComponentXeGenericErrorModal.FigureContent').value,
    },
    actionText: {
      type: String,
      required: false,
      default: useI18nStore().$t('ComponentXeGenericErrorModal.ActionText').value,
    },
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { model } = useVModel(props, emit)
    const actionClick = () => {
      emit('actionClick')
    }
    return {
      model,
      actionClick,
    }
  },
}
</script>
<style scoped></style>
